<template>
    <div>

    </div>
</template>
<script>
import Cookies from 'js-cookie';
import {tokenName} from '@/router/init.js'
import util from '@/utils/tools.js';

export default {
    name: 'quicklogin',
    data () {
        return {
            access_token:'',
            loading:false,
        };
    },
    created() {
        if(this.$route.params.access_token){
            this.access_token = this.$route.params.access_token;
        }
    },
    mounted () {
        this.getToken();
    },
    methods: {
        getToken () {
            if (this.loading) {
                return;
            }
            this.loading = true;
            let data = {
                access_token: this.access_token,
            };
            this.api.index.LoginQuick(data).then((res)=>{
                this.loading = false;

                this.$store.commit('login', {
                    accessToken: res.access_token,
                });
                this.access_token = res.access_token;

                //是否登录的标志
                // Cookies.set('liteAccessToken', res.access_token);
              let domain = util.getDomain();
              //是否登录的标志
              // Cookies.set('liteAccessToken', res.access_token);
              //添加ai问答token

              Cookies.set(tokenName, res.access_token,{domain});
                //util.setCookie('liteLoginAccount', this.form.mobile, 30);

                this.$store.commit('setLoginStatus', true);

                this.loginSuc();
                // this.$router.push('/');
                // this.getUserInfo();
            }).catch((err)=>{
                this.loading = false;
            });
        },
        getUserInfo () {
            // 获取用户信息
            const userInfo = this.$store.state.user.userInfo;

            console.log('login.vue.getUserInfo.userInfo: ')
            console.log(userInfo)

            this.$store.commit('requestUserInfo', userInfoRequest);
        },
        loginSuc(){
            this.api.index.userDetail().then((res)=>{
                //console.log(res.user.menus,this.$store.state.app.menuListAll)
                //let menuList = util.formateMenu( {menu:res.user.menus, all:this.$store.state.app.menuListAll});
                this.$router.push({
                    path:'/home'
                })
            })
        }
    },
}
</script>

